<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-modal
        :title="$t('calendarModule.buttons.openEditSlotForm')"
        size="xl"
        ref="editSlotFormModal"
        @hide="editSlotModalOpen = false"
        hide-footer
      >
        <MassSlotsActions
          v-if="editSlotModalOpen"
          :slots="null"
          :is-edit="false"
          :is-from-calendar-module-settings="true"
          @slotsChanged="slotsAddedOrSaved"
        />
      </b-modal>
      <b-modal
        :title="$t('calendarModule.modalTitles.bookVacation')"
        size="xl"
        ref="bookVacationModal"
        @hide="bookVacationModalOpen = false"
        hide-footer
      >
        <BookVacationForm
          v-if="bookVacationModalOpen"
          @calendarAddedOrChanged="closeBookVacationForm()"
        />
      </b-modal>
      <b-modal
        :title="$t('calendarModule.modalTitles.globalSettings')"
        size="xl"
        ref="generalOptionsFormModal"
        @hide="generalOptionsFormOpen = false"
        hide-footer
      >
        <GeneralOptionsForm
          v-if="generalOptionsFormOpen"
          :current-user-with-slot-information="currentUserWithSlotInformation"
          @calendarAddedOrChanged="generalOptionsAddedOrChanged"
        />
      </b-modal>
      <b-modal
        ref="defaultSlotFormModal"
        @hide="defaultSlotFormOpen = false"
        hide-footer
        size="xl"
        :title="
          selectedDefaultSlot
            ? $t('calendarModule.modalTitles.editDefaultSlot')
            : $t('calendarModule.modalTitles.addDefaultSlot')
        "
      >
        <DefaultSlotForm
          v-if="defaultSlotFormOpen"
          :for-date="defaultSlotFormOpen"
          :the-default-slot="selectedDefaultSlot"
          @slotsAddedOrSaved="defaultSlotsAddedOrSaved"
        />
      </b-modal>
      <b-modal
        ref="slotFormModal"
        size="xl"
        @hide="slotFormOpen = false"
        :title="
          selectedSlot
            ? $t('calendarModule.modalTitles.editSlot')
            : $t('calendarModule.modalTitles.addSlot')
        "
        hide-footer
      >
        <SlotForm
          v-if="slotFormOpen"
          :for-date="slotFormOpen"
          :the-slot="selectedSlot"
          @slotsAddedOrSaved="slotsAddedOrSaved"
        />
      </b-modal>
      <div class="cm-settings-line-end mb-5">
        <b-button
          @click="() => openGeneralOptionsForm()"
          class="btn btn-primary m-2"
          size="xs"
        >
          <b-icon-gear-fill class="mr-1" />
          {{ $t("calendarModule.buttons.globalSettings") }}
        </b-button>
        <b-button
          @click="() => openSlotForm()"
          class="btn btn-primary m-2"
          size="xs"
        >
          <b-icon-calendar2 class="mr-1" />
          {{ $t("calendarModule.buttons.openSlotForm") }}
        </b-button>
        <b-button
          @click="() => openDefaultSlotForm()"
          class="btn btn-primary m-2"
          size="xs"
        >
          <b-icon-calendar3-range class="mr-1" />
          {{ $t("calendarModule.buttons.openDefaultSlotForm") }}
        </b-button>
       <!-- <b-button
          @click="() => openEditSlotForm()"
          class="btn btn-primary m-2"
          size="xs"
        >
          <b-icon-pencil class="mr-1" />
          {{ $t("calendarModule.buttons.openEditSlotForm") }}
        </b-button>-->
        <!-- <b-button
          @click="
            () => {
              $emit('showSlotList');
            }
          "
          class="btn btn-secondary m-2"
          size="xs"
        >
          <b-icon-list class="mr-1" />
          {{ $t("calendarModule.buttons.showSlotList") }}
        </b-button>-->
        <b-button
          @click="() => openBookVacationForm()"
          class="btn btn-primary m-2"
          size="xs"
        >
          <b-icon-calendar-check class="mr-1" />
          {{ $t("calendarModule.buttons.bookVacation") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";
import * as userApi from "../../store/modules/users/users.api";

import * as moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "CalendarModuleSettings",
  components: {
    MassSlotsActions: () =>
      import("../../components/calendarModule/common/MassSlotsActions"),
    SlotForm: () => import("../../components/calendarModule/forms/SlotForm"),
    GeneralOptionsForm: () => import("./forms/GeneralOptionsForm"),
    DefaultSlotForm: () =>
      import("../../components/calendarModule/forms/DefaultSlotForm"),
    BookVacationForm: () =>
      import("../../components/calendarModule/forms/BookVacationForm")
  },
  props: ["theCalendars"],
  data() {
    return {
      currentUser: null,
      currentUserWithSlotInformation: null,
      moment,
      editSlotModalOpen: false,
      bookVacationModalOpen: false,
      selectedSlot: null,
      selectedDefaultSlot: null,
      slotFormOpen: false,
      defaultSlotFormOpen: false,
      generalOptionsFormOpen: false,
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendarFormOpen: false,
      testCenterSettingsOpen: false,
      exportOpen: false
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    openBookVacationForm() {
      this.$refs["bookVacationModal"].show();
      this.bookVacationModalOpen = true;
    },
    closeBookVacationForm() {
      this.$refs["bookVacationModal"].hide();
      this.bookVacationModalOpen = false;
      this.calendarAddedOrChanged();
    },
    openGeneralOptionsForm() {
      userApi
        .getUser(this.currentUser._id)
        .then(res => {
          const user = res.data.data;
          this.currentUserWithSlotInformation = user;
          this.$refs["generalOptionsFormModal"].show();
          this.generalOptionsFormOpen = true;
        });
    },
    closeGeneralOptionsForm() {
      this.$refs["generalOptionsFormModal"].hide();
      this.generalOptionsFormOpen = false;
    },
    openSlotForm(slot = null) {
      this.$refs["slotFormModal"].show();
      this.slotFormOpen = moment(); //day.format("YYYY-MM-DD");
      this.selectedSlot = slot;
    },
    openDefaultSlotForm(defaultSlot = null) {
      this.$refs["defaultSlotFormModal"].show();
      this.defaultSlotFormOpen = moment(); //day.format("YYYY-MM-DD");
      this.selectedDefaultSlot = defaultSlot;
    },
    openEditSlotForm() {
      this.editSlotModalOpen = true;
      this.$refs["editSlotFormModal"].show();
    },
    openEditSlotFormAddedOrSaved() {
      this.editSlotModalOpen = false;
      this.$refs["editSlotFormModal"].hide();
      this.calendarAddedOrChanged();
    },
    defaultSlotsAddedOrSaved() {
      this.$refs["defaultSlotFormModal"].hide();
      this.slotFormOpen = null;
      this.selectedSlot = null;
      this.calendarAddedOrChanged();
    },
    slotsAddedOrSaved() {
      this.$refs["slotFormModal"].hide();
      this.slotFormOpen = null;
      this.selectedSlot = null;
      this.calendarAddedOrChanged();
    },
    generalOptionsAddedOrChanged() {
      this.$refs["generalOptionsFormModal"].hide();
      this.generalOptionsFormOpen = false;
      this.calendarAddedOrChanged();
    },
    calendarAddedOrChanged() {
      this.$emit("calendarAddedOrChanged");
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>

<style scoped></style>
